<template>
  <b-card v-if="data" no-body class="card-statistics">
    <b-overlay variant="white" :show="show" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
      <b-card-header>
        <b-card-title>Kur Türüne Göre Toplam</b-card-title>
        <b-card-text class="font-small-2 mr-25 mb-0"> {{ new Date() }} </b-card-text>
      </b-card-header>
      <b-card-body class="statistics-body">
        <b-row>
          <b-col v-for="(item, i) in data" :key="i" xl="3" sm="6">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="55" :variant="item.color">
                  <b class="h2 text-white pt-50">{{ item.icon }}</b>
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ item.toplam | cur }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ item.banka_kodu }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
export default {
  components: {},
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    icon: [String],
    color: [String],
    show: [Boolean],
  },
};
</script>
