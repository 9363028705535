<template>
  <div>
    <h4 class="mb-2">Site İstatistik Bilgisi</h4>
    <b-row>
      <b-col cols="12" md="4">
        <router-link
          :to="{
            name: 'sayfa-listele',
          }"
        >
          <static-widget :show="show" icon="BookIcon" statisticTitle="Sayfa İçerik" :statistic="data.sayfalar" color="primary" />
        </router-link>
      </b-col>
      <b-col cols="12" md="4">
        <router-link
          :to="{
            name: 'slider',
          }"
        >
          <static-widget :show="show" icon="ImageIcon" statisticTitle="Slider İçerik" :statistic="data.slider" color="info" />
        </router-link>
      </b-col>
      <b-col cols="12" md="4">
        <router-link
          :to="{
            name: 'urun',
          }"
        >
          <static-widget
            :show="show"
            icon="ShoppingCartIcon"
            statisticTitle="Ürün İçerik"
            :statistic="data.urunler"
            color="success"
          />
        </router-link>
      </b-col>
    </b-row>
    <b-row>
      <!-- <b-col cols="12" md="4">
        <router-link
          :to="{
            name: 'rezervasyonlar',
          }"
        >
          <static-widget
            :show="show"
            icon="LayersIcon"
            statisticTitle="Rezervasyonlar"
            :statistic="data.rezervasyonlar"
            color="primary"
          />
        </router-link>
      </b-col> -->
      <b-col cols="12" md="4">
        <router-link
          :to="{
            name: 'yorumlar',
          }"
        >
          <static-widget :show="show" icon="TwitchIcon" statisticTitle="Yorumlar" :statistic="data.yorumlar" color="info" />
        </router-link>
      </b-col>
      <!-- <b-col cols="12" md="4">
        <router-link
          :to="{
            name: 'uyeler',
          }"
        >
          <static-widget :show="show" icon="UsersIcon" statisticTitle="Üyeler" :statistic="data.uyeler" color="success" />
        </router-link>
      </b-col> -->
    </b-row>
    <!-- <h4 class="mb-2 mt-3">Finan Durum Bilgisi</h4>
    <b-row class="match-height">
      <b-col xl="4" md="6">
        <default-price-total :show="show" :price="data.price" />
      </b-col>
      <b-col xl="8" md="6">
        <other-price-total :show="show" :data="data.otherPrice" />
      </b-col>
    </b-row> -->
    <b-row>
      <b-col cols="12" md="4">
        <latest-comments :show="show" :data="data.sonYorumlar" />
      </b-col>
      <b-col cols="12" md="8">
        <!-- <most-reservations :show="show" :rows="data.enSonGelenRezervasyonlar" /> -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import StaticWidget from './component/staticWidget.vue';
import DefaultPriceTotal from './component/defaultPriceTotal.vue';
import otherPriceTotal from './component/otherPriceTotal.vue';
import latestComments from './component/latestComments.vue';
import mostReservations from './component/mostReservations.vue';
import axiosIns from '@/libs/axios';
export default {
  components: {
    StaticWidget,
    DefaultPriceTotal,
    otherPriceTotal,
    latestComments,
    mostReservations,
  },
  data: () => ({
    show: false,
    data: {
      sayfalar: 0,
      slider: 0,
      urunler: 0,
      rezervasyonlar: 0,
      yorumlar: 0,
      uyeler: 0,
      price: {},
      otherPrice: [
        {
          toplam: 23.99,
          banka_kodu: 'TRY',
          icon: '₺',
          color: 'primary',
        },
        {
          toplam: 23.99,
          banka_kodu: 'EUR',
          icon: '€',
          color: 'success',
        },
        {
          toplam: 23.99,
          banka_kodu: 'USD',
          icon: '$',
          color: 'danger',
        },
        {
          toplam: 23.99,
          banka_kodu: 'GBP',
          icon: '£',
          color: 'warning',
        },
      ],
      sonYorumlar: [],
      enSonGelenRezervasyonlar: [],
    },
  }),

  created() {
    this.handlerGetAllData();
  },
  methods: {
    async handlerGetAllData() {
      this.show = true;
      await axiosIns
        .get('/home/tum-istatistikleri-getir')
        .then((res) => {
          const data = res.data.result;
          this.data.sayfalar = data.sayfalar;
          this.data.slider = data.slider;
          this.data.urunler = data.urunler;
          this.data.rezervasyonlar = data.rezervasyonlar;
          this.data.yorumlar = data.yorumlar;
          this.data.uyeler = data.uyeler;
          this.data.price = data.sabitKur[0];
          this.data.sonYorumlar = data.sonYorumlar;
          this.data.enSonGelenRezervasyonlar = data.enSonGelenRezervasyonlar;
          const toplamSatis = data.satisToplam;

          toplamSatis.forEach((element) => {
            const priceIndex = this.data.otherPrice.findIndex((x) => x.banka_kodu == element.banka_kodu);
            this.data.otherPrice[priceIndex].toplam = element.toplamSatis;
          });
          this.show = false;
        })
        .catch((err) => {
          this.show = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
