<template>
  <b-card v-if="price" class="card-congratulation-medal">
    <b-overlay variant="white" :show="show" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
      <h5>Varsayılan Kur Toplam</h5>
      <b-card-text class="font-small-3"> Sistemde kayıtlı varsayılan kur toplamı </b-card-text>
      <h3 class="mb-75 mt-2 pt-50">
        <b-link>{{ price._id }} {{ price.sabit_toplam | cur }}</b-link>
      </h3>
      <b-button variant="primary"> Finans </b-button>
      <b-img :src="require('@/assets/images/illustration/badge.svg')" class="congratulation-medal" alt="Medal Pic" />
    </b-overlay>
  </b-card>
</template>

<script>
export default {
  components: {},
  props: {
    price: {
      type: Object,
      required: true,
    },
    show: [Boolean],
  },
  methods: {},
};
</script>
