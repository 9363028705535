<template>
  <b-card no-body>
    <b-overlay variant="white" :show="show" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
      <b-card-header>
        <b-card-title>En Son Rezervasyonlar </b-card-title>
      </b-card-header>
      <b-card-body>
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :line-numbers="false"
          :pagination-options="{
            enabled: false,
            perPage: pageLength,
          }"
          styleClass="vgt-table striped bordered"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'save_date'">
              <router-link
                :to="{
                  name: 'rezervasyon-detay',
                  params: { k_no: props.row.k_no },
                }"
              >
                {{ props.row.save_date | momentFull }}
              </router-link>
            </span>
            <span v-else-if="props.column.field === 'tarih'" class="pr-25">
              <router-link
                :to="{
                  name: 'rezervasyon-detay',
                  params: { k_no: props.row.k_no },
                }"
              >
                {{ props.row.tarih | moment }}
              </router-link>
            </span>
            <span v-else-if="props.column.field === 'k_no'" class="pr-25">
              <router-link
                :to="{
                  name: 'rezervasyon-detay',
                  params: { k_no: props.row.k_no },
                }"
              >
                {{ props.row.k_no }}
              </router-link>
            </span>
            <span v-else-if="props.column.field === 'urun_adi'">
              <span>{{ props.row.urun[0].urun_adi | str_limit(30) }}</span>
            </span>
            <span v-else-if="props.column.field === 'statu'">
              <b-badge class="rounded-0" :style="`background-color:${statuColor(props.row.statu)};`">
                {{ statuText(props.row.statu) }}
              </b-badge>
            </span>
            <span v-else-if="props.column.field === 'action'" class="float-right px-1">
              <b-button-group size="sm" class="pb-25">
                <b-button
                  variant="primary"
                  class="rounded-0"
                  :to="{
                    name: 'rezervasyon-detay',
                    params: { k_no: props.row.k_no },
                  }"
                >
                  <feather-icon icon="EyeIcon" />
                </b-button>
              </b-button-group>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import { VueGoodTable } from 'vue-good-table';
import vSelect from 'vue-select';
export default {
  components: {
    VueGoodTable,
    vSelect,
  },
  props: {
    rows: {
      type: Array,
      required: true,
    },
    show: [Boolean],
  },
  computed: {
    statuText() {
      const statusText = {
        1: 'Onay Bekliyor',
        2: 'Değişiklik İsteği',
        3: 'Ücret İade İsteği',
        4: 'Onaylandı',
        5: 'İptal Edilenler',
      };

      return (status) => statusText[status];
    },
    statuColor() {
      const statusColor = {
        1: '#1377d3',
        2: '#ffc107',
        3: '#DA4E8F',
        4: '#20c997',
        5: '#ea5455',
      };

      return (status) => statusColor[status];
    },
    odemeText() {
      const statusText = {
        1: 'Kapıda Ödeme',
        2: 'Havale EFT',
        3: 'Kredi Kartı',
      };

      return (status) => statusText[status];
    },
    odemeColor() {
      const statusColor = {
        1: '#1377d3',
        2: '#20c997',
        3: '#ff9f43',
      };

      return (status) => statusColor[status];
    },
  },
  data: () => ({
    columns: [
      {
        label: 'İşlem Tarihi',
        field: 'save_date',
        width: '15%',
      },
      {
        label: 'Rez. Tarihi',
        field: 'tarih',
        width: '15%',
      },
      {
        label: '#Rez No',
        field: 'k_no',
        width: '15%',
      },
      {
        label: 'Ürün Adı',
        field: 'urun_adi',
      },
      {
        label: 'Statu',
        field: 'statu',
        width: '10%',
        tdClass: 'text-right',
      },
      {
        label: 'İşlemler',
        field: 'action',
        width: '3%',
      },
    ],
  }),
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
table.vgt-table {
  font-size: 14px;
  thead {
    background-color: rgba($black, 0.07);
  }
  td {
    padding: 3px 0px 0px 5px !important;
    vertical-align: middle !important;
  }
}
</style>
