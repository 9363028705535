var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h4',{staticClass:"mb-2"},[_vm._v("Site İstatistik Bilgisi")]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('router-link',{attrs:{"to":{
          name: 'sayfa-listele',
        }}},[_c('static-widget',{attrs:{"show":_vm.show,"icon":"BookIcon","statisticTitle":"Sayfa İçerik","statistic":_vm.data.sayfalar,"color":"primary"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('router-link',{attrs:{"to":{
          name: 'slider',
        }}},[_c('static-widget',{attrs:{"show":_vm.show,"icon":"ImageIcon","statisticTitle":"Slider İçerik","statistic":_vm.data.slider,"color":"info"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('router-link',{attrs:{"to":{
          name: 'urun',
        }}},[_c('static-widget',{attrs:{"show":_vm.show,"icon":"ShoppingCartIcon","statisticTitle":"Ürün İçerik","statistic":_vm.data.urunler,"color":"success"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('router-link',{attrs:{"to":{
          name: 'yorumlar',
        }}},[_c('static-widget',{attrs:{"show":_vm.show,"icon":"TwitchIcon","statisticTitle":"Yorumlar","statistic":_vm.data.yorumlar,"color":"info"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('latest-comments',{attrs:{"show":_vm.show,"data":_vm.data.sonYorumlar}})],1),_c('b-col',{attrs:{"cols":"12","md":"8"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }