var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('b-overlay',{attrs:{"variant":"white","show":_vm.show,"spinner-variant":"primary","blur":"0","opacity":".75","rounded":"sm"}},[_c('b-card-header',[_c('b-card-title',[_vm._v("En Son Rezervasyonlar ")])],1),_c('b-card-body',[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"line-numbers":false,"pagination-options":{
          enabled: false,
          perPage: _vm.pageLength,
        },"styleClass":"vgt-table striped bordered"},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'save_date')?_c('span',[_c('router-link',{attrs:{"to":{
                name: 'rezervasyon-detay',
                params: { k_no: props.row.k_no },
              }}},[_vm._v(" "+_vm._s(_vm._f("momentFull")(props.row.save_date))+" ")])],1):(props.column.field === 'tarih')?_c('span',{staticClass:"pr-25"},[_c('router-link',{attrs:{"to":{
                name: 'rezervasyon-detay',
                params: { k_no: props.row.k_no },
              }}},[_vm._v(" "+_vm._s(_vm._f("moment")(props.row.tarih))+" ")])],1):(props.column.field === 'k_no')?_c('span',{staticClass:"pr-25"},[_c('router-link',{attrs:{"to":{
                name: 'rezervasyon-detay',
                params: { k_no: props.row.k_no },
              }}},[_vm._v(" "+_vm._s(props.row.k_no)+" ")])],1):(props.column.field === 'urun_adi')?_c('span',[_c('span',[_vm._v(_vm._s(_vm._f("str_limit")(props.row.urun[0].urun_adi,30)))])]):(props.column.field === 'statu')?_c('span',[_c('b-badge',{staticClass:"rounded-0",style:(("background-color:" + (_vm.statuColor(props.row.statu)) + ";"))},[_vm._v(" "+_vm._s(_vm.statuText(props.row.statu))+" ")])],1):(props.column.field === 'action')?_c('span',{staticClass:"float-right px-1"},[_c('b-button-group',{staticClass:"pb-25",attrs:{"size":"sm"}},[_c('b-button',{staticClass:"rounded-0",attrs:{"variant":"primary","to":{
                  name: 'rezervasyon-detay',
                  params: { k_no: props.row.k_no },
                }}},[_c('feather-icon',{attrs:{"icon":"EyeIcon"}})],1)],1)],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }